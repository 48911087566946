<template>
  <div
    :style="{ position: scrollPosition >= 100 ? 'fixed' : 'absolute', background: scrollPosition >= 100 ? 'white' : '', paddingTop: scrollPosition >= 100 ? '0px' : '0.6rem' }"
    class="container">
    <div class="nav_bar">
      <div class="left"></div>
      <div class="right">
        <router-link class="title_arr" v-for="(item, index) in title" active-class="active" :key="index" tag="div"
          :to="item.path">{{ item.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: 0,
      title: [
        {
          name: '首页',
          path: '/pc/home'
        },
        {
          name: '产品介绍',
          path: '/pc/productIntroduction'
        },
        {
          name: '解决方案',
          path: '/pc/solution'
        },
        {
          name: '技术',
          path: '/pc/technology'
        },
        {
          name: '关于我们',
          path: '/pc/aboutUs'
        },
        {
          name: '联系我们',
          path: '/pc/contactUs'
        },
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY;
    });
  }
}
</script>

<style lang="scss" scoped>
.container {
  transition-duration: .3s;
  top: 0;
  left: 0;
  min-width: 1024px;
  z-index: 1000;
  width: 100%;

  .nav_bar {
    width: 100%;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .left {
      height: 2.4rem;
      width: 16.5%;
      background-image: url('../../assets/logo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 2.4rem;
    }

    .right {
      height: 1.88rem;
      width: 40%;
      margin-right: 2.6rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.64rem;
      font-weight: 500;
      color: #666666;

      .title_arr {
        cursor: pointer;
      }

      .active {
        color: #4e78ff;
      }
    }
  }
}
</style>