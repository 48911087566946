<template>
  <div class="fotter">
    <div class="fotter_inside">
      <div class="fotter_one_inside">
        <div>地址：广东省广州市天河区黄埔大道汇金金融中心B塔21楼 18/19号</div>
      </div>
    </div>
    <div class="fotter_inside">
      <div class="fotter_two_inside">
        <div>E_MAIL：manager@gdcreate.com</div>
      </div>
    </div>
    <div class="fotter_inside">
      <div class="fotter_three_inside">
        <div class="left">
          <div class="left_inside">
            <div>版权所有：广州克里特科技有限公司 Copyright 2023 All rights reserved</div>
          </div>
        </div>
        <div class="right">
          <div class="right_inside">
            <!-- <div>备案序号：粤ICP备14040842号</div>
            <div>粤公网安备 44130202000552号 </div> -->
            <div>备案序号：<a href="https://beian.miit.gov.cn" target="_new" class="fotter_three_inside">粤ICP备2024342005号</a> 粤公网安备 441XXXXXXXX号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="QRCode">
      <div class="inside">
        <!-- <el-image :src="code" fit="cover"/> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: require('../../assets/二维码.jpg'),
    }
  }
}
</script>

<style lang="scss" scoped>
.fotter{
  min-width: 1024px;
  padding-top: 1.24rem;
  width: 100%;
  height: 8.72rem;
  background: #252A30;
  font-size: 0.56rem;
  .fotter_inside{
    width: 100%;
    height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.52rem;
    .fotter_one_inside{
      width: 30%;
      height: 100%;
      margin-left: 20px;
      display: flex;
      justify-content: space-between;
      color: white;
      align-items: center;
    }
    .fotter_two_inside{
      width: 17%;
      height: 100%;
      // display: flex;
      // justify-content: space-between;
      color: white;
      align-items: center;
      margin-left: 42px;
      
    }
    .fotter_three_inside{
      width: 35%;
      height: 100%;
      justify-content: center;
      color: white;
      .left{
        width: 100%;
        .left_inside{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          margin-left: 35px
        }
      }
      .right{
        width: 100%;
        .right_inside{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 55px
        }
      }
    }
  }
  .QRCode{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.16rem;
    padding-bottom: 0.4rem;
    .inside{
      width: 3.88rem;
      height: 3.88rem;
      border-radius: 5px;
      .el-image{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>