<template>
  <div>
    <NavigationBar/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import NavigationBar from '../../components/NavigationBar/index.vue'
import Footer from '../../components/Footer/index.vue'
export default {
  components: {
    NavigationBar,
    Footer
  }
}
</script>

<style lang="scss" scoped>

</style>